import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { captureException } from '@sentry/react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'
import { decode } from 'jsonwebtoken'
import { toast } from 'react-toastify'

import { API } from 'app'
import {
  clearCurrentUserToken,
  getCurrentUserToken,
  setCurrentUserToken,
} from 'utils'

const retryLink = new RetryLink()

const logError = (error: string) => {
  try {
    captureException(new Error(error))
  } catch (error_) {
    // eslint-disable-next-line no-console
    console.log('errorLink: error calling Sentry.captureException', error_)
  }
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      logError(JSON.stringify(error))
      toast.error(error.message)
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
          error.locations,
        )}, Path: ${JSON.stringify(error.path)}`,
      )
    })
  }
  return forward(operation)
})

const authorizationLink = setContext((_req, { headers }) => {
  const authorization = getCurrentUserToken()

  const authHeader = authorization ? { authorization } : {}

  const authConfig: {
    headers: Headers
  } = {
    headers: {
      ...headers,
      ...authHeader,
    },
  }

  return authConfig
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRefreshToken: () => Promise<Response> = async () => {
  const token = getCurrentUserToken()

  if (!token) {
    return null as unknown as Response
  }

  return await fetch(`${process.env.REACT_APP_API!}`, {
    body: JSON.stringify({
      query: `
          mutation {
            refresh {
              token
            }
          }
        `,
    }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
}

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'refresh',
  fetchAccessToken: getRefreshToken,
  handleError: (error) => {
    console.error('Cannot refresh access token:', error)
  },
  handleFetch: (refresh: { token: string }) => {
    setCurrentUserToken(refresh.token)
  },
  isTokenValidOrUndefined: () => {
    const token = getCurrentUserToken()

    if (!token) {
      return true
    }
    // @ts-expect-error exp exists on token
    if (token && decode(token)?.exp * 1000 > Date.now()) {
      return true
    }

    return false
  },
})

const httpLink = createUploadLink({
  credentials: 'include',
  fetch,
  uri: API,
})

const cache = new InMemoryCache()

const link = from([
  refreshLink,
  authorizationLink,
  errorLink,
  retryLink,
  httpLink,
])

export const client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
  link,
})
